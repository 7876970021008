import VueRouter, {Route} from 'vue-router'
import {RouteConfig} from 'vue-router/types/router'
import Vue from 'vue'
import {IRouterMeta} from '@/typings/IRouterMeta'
import {checkPageAuth} from '@/permissions'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: 'chat',
        meta: {
            noHeader: true,
            noPageWrap: true,
            permissions: 'full-auth',
        } as IRouterMeta
    },
    {
        path: '/chat',
        name: 'chat',
        meta: {
            hasChatBlock: true,
            hasMainRoutes: true,
            permissions: 'full-auth'
        } as IRouterMeta,
        component: () => import('./pages/Chat/ChatPage.vue'),
    },
    {
        path: '/chatbots',
        name: 'chatbots',
        meta: {
            hasMainRoutes: true,
            hasActorSearch: true,
            hasChatBlock: true,
            chatBlockForceHide: true,
            hasNavigationBlock: true,
            title: 'Chatbots AI'
        } as IRouterMeta,
        component: () => import('./pages/Marketplace/MarketplacePage.vue'),
    },
    {
        path: '/chatbots/:id',
        name: 'actor',
        meta: {
            hasActorSearch: true,
            hasChatBlock: true,
            chatBlockForceHide: true,
            hasNavigationBlock: true,
        } as IRouterMeta,
        component: () => import('./pages/Actor/ActorPage.vue'),
    },
    {
        path: '/shortcut',
        name: 'shortcut',
        meta: {
            hasChatBlock: true,
            hasMainRoutes: true,
            permissions: 'full-auth'
        } as IRouterMeta,
        component: () => import('./pages/Shortcut/ShortcutPage.vue')
    },
    {
        path: '/guide',
        name: 'guide',
        meta: {
            hasChatBlock: true,
            hasMainRoutes: true,
            permissions: 'full-auth'
        } as IRouterMeta,
        component: () => import('./pages/Guide/GuidePage.vue')
    },
    {
        path: '/account',
        name: 'account',
        meta: {
            hasChatBlock: true,
            hasMainRoutes: true,
            permissions: 'full-auth'
        } as IRouterMeta,
        component: () => import('./pages/Account/AccountPage.vue')
    },
    {
        path: '/terms-of-use',
        name: 'terms-of-use',
        component: () => import('./pages/TermsOfUser/TermsOfUsePage.vue')
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('./pages/PrivacyPolicy/PrivacyPolicyPage.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
        } as IRouterMeta,
        component: () => import('./pages/Login/LoginPage.vue')
    },
    {
        path: '/registration',
        name: 'registration',
        meta: {
        } as IRouterMeta,
        component: () => import('./pages/Registration/RegistrationPage.vue')
    },
    {
        path: '/buy',
        name: 'buy',
        meta: {
            transparentHeader: true,
            permissions: 'full-auth',
        } as IRouterMeta,
        component: () => import('./pages/BuySubscription/BuySubscriptionPage.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('./pages/Landing/LandingPage.vue'),
        meta: {
            noPageWrap: true,
            noHeader: true,
        } as IRouterMeta,
    },
    {
        path: '/landing',
        redirect: { name: 'about' }
    },
    {
        path: '/confirm-account',
        name: 'confirm-account',
        component: () => import('./pages/Confirm/ConfirmEmailPage.vue'),
        meta: {
            noPageWrap: true,
            noHeader: true,
            permissions: 'not-confirmed'
        } as IRouterMeta,
    },
    {
        path: '/password-recovery',
        name: 'password-recovery',
        component: () => import('./pages/ForgotPassword/ForgotPasswordPage.vue'),
        meta: {
            noHeader: true,
        } as IRouterMeta,
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => import('./pages/ResetPassword/ResetPasswordPage.vue'),
        meta: {
            noHeader: true,
        } as IRouterMeta,
    },
    {
        path: '/connect',
        redirect: { name: 'about' },
    },
    {
        path: "*",
        name: '404',
        component: () => import('./pages/SystemPages/Page404.vue')
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

router.beforeEach(checkPageAuth)
router.beforeEach(setTitle)

export function setTitle(to: Route, from: Route, next: Function) {
    const meta = to.meta as IRouterMeta
    document.title = meta.title ?? 'Chat AI'
    next()
}

export default router